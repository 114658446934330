import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

interface PropTypes {
    title: string;
    description: string;
    closeCallback: Function;
}
export const SlideOverHeader = ({ title, description, closeCallback }: PropTypes) => {
    return (
        <div className="px-4 py-6 bg-gray-50 sm:px-6">
          <div className="flex items-start justify-between space-x-3">
            <div className="space-y-1">
              <Dialog.Title className="text-lg font-medium text-gray-900">{ title }</Dialog.Title>
              <p className="text-sm text-gray-500">
                { description }
              </p>
            </div>
            <div className="h-7 flex items-center">
              <button
                type="button"
                className="text-gray-400 hover:text-gray-500"
                onClick={() => closeCallback(false)}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
    )
}