
 import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

interface PropTypes {
    name: string;
    label: string;
    defaultValue?: string;
    onKeyUpCallback: Function;
    disabled?: boolean;
    error?: string;
}

export const SlideOverFormEditor = ({ name, label, defaultValue, onKeyUpCallback, disabled, error }: PropTypes) => {
    const handleChange = (value: string) => {
      onKeyUpCallback(value);
    }

    return (
        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              {label}
            </label>
          </div>
          <div className="sm:col-span-10">
            <Editor
                apiKey='5zwo7nzkgiawmi536ihzloupimwlcnoxrlhhhsgsm5ogilic'
                value={defaultValue}
                init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onEditorChange={handleChange}
            />
            {
              error && (
                <p className="text-red-500">{ error }</p>
              )
            }
            </div>
        </div>
    );
}