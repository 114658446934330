import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../../api/auth/api";
import { PasswordResetBody } from "../../../api/auth/types";
import { useAppDispatch } from "../../../shared/redux/hooks";
import { updateAccessToken } from "../slice";
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
  
export const ResetPassword = () => {
    const query = useQuery();
    const dispatch = useAppDispatch();
    const { token } = useParams<{
        token?: string;
    }>();

    const [ form, updateForm ] = useState<PasswordResetBody>({
        password: '',
        password_confirmation: '',
        email: query.get('email') as string,
        token: token as string,
    });
    const [ errors, updateErrors ] = useState<{
      show: boolean;
      error: Array<string>;
    }>({
      show: false,
      error: [],
    })

    const [ resetPassword, { isLoading }] = useResetPasswordMutation();

    const handlePasswordReset = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const response: any = await resetPassword(form);

        if (response.data) {
            // Login
            localStorage.setItem('access_token', response.data.access_token);
            dispatch(updateAccessToken(response.data.access_token));
        } else {
            if (response.error.status == 422) {
                updateErrors({
                  show: true,
                  error: Object.values(response.error.data.errors).map((error: any) => error[0]),
                })
            } else {
                updateErrors({
                  show: true,
                  error: ['Please try again later'],
                })
            }
        }
    }

    console.log(token, query.get('email'));
    
    return (
        <>
            <div className="min-h-full flex h-screen">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div className="mt-8">
                            <div className="mt-6">
                                <form action="#" method="POST" className="space-y-6">
                                { errors.show && (
                                <div className="rounded-md bg-red-50 p-4">
                                    <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">There was an error with your submission</h3>
                                        <div className="mt-2 text-sm text-red-700">
                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                            { errors.error.map(error => (
                                                <li>{error}</li>
                                                )
                                            )}
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                )}
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            disabled={true}
                                            value={form.email}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        New Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="password"
                                            autoComplete="email"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onKeyUp={(event) => updateForm({ ...form, password: (event.target as HTMLInputElement).value})}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Confirm New Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="password"
                                            autoComplete="email"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onKeyUp={(event) => updateForm({ ...form, password_confirmation: (event.target as HTMLInputElement).value})}
                                        />
                                    </div>
                                </div>
    
                                <div className="flex items-center justify-between">  
                                    <div className="text-sm">
                                        <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            Login?
                                        </Link>
                                    </div>
                                </div>
    
                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={handlePasswordReset}
                                        disabled={false}
                                    >
                                    { false ? 'Loading...' : 'Reset Password'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1 bg-black">
                <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
                />
          </div>
        </div>
    </>
    );
}