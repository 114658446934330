import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../shared/redux/store';
import { Diet } from './types';

export const dietAPI = createApi({
    reducerPath: 'dietAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL as string,
        prepareHeaders: (headers, { getState }) => {
            headers.set('accept', 'application/json');
            headers.set('authorization', `Bearer ${(getState() as RootState).auth.access_token}`)

            return headers;
        },
    }),
    tagTypes: ['Diet'],
    endpoints: (builder) => ({
        fetchAllDiets: builder.query<Array<Diet>, null>({
            query: () => ({
                url: "diets",
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { data: Array<Diet> }) => response.data,
            providesTags: ['Diet'],
        }),
    }),
});

export const { useFetchAllDietsQuery } = dietAPI;