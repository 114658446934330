import React from "react"
import { Redirect, useLocation } from "react-router";
import { useGetUserQuery, userApi } from "../../../api/user/api";
import { Loading } from "../../../shared/components/Loading";
import { useAppDispatch, useAppSelector } from "../../../shared/redux/hooks";
import { selectUser, updateUser } from "../../user/slice";
import { selectAccessToken } from "../slice";

function isAuthRoute(route: string): boolean
{
    return route === '/login' || route === '/password/reset' || route.includes('/password/reset');
}

export const UserMiddleware = ({ children }: any) => {
    const accessToken = useAppSelector(selectAccessToken);
    const user = useAppSelector(selectUser);
    const location = useLocation();
    const { data } = useGetUserQuery(null, {
        skip: typeof user === "object",
    });
    const dispatch = useAppDispatch();

    if (accessToken && !user) {
        dispatch(updateUser(data));

        return (
            <Loading />
        )
    }

    if (accessToken === undefined && isAuthRoute(location.pathname) === false) {
        return (
            <Redirect to={{
                pathname: '/login',
            }}
            />
        );
    } else if (accessToken && user && isAuthRoute(location.pathname) === true) {
        return (
            <Redirect to={{
                pathname: '/',
            }}
            />
        );
    }

    return children as React.ReactElement;
}