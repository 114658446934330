import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetAccessTokenMutation } from "../../../api/auth/api";
import { useAppDispatch } from "../../../shared/redux/hooks";
import { updateAccessToken } from "../slice";

export const Login = () => {
  const [ form, updateForm ] = useState<{
    email: string,
    password: string,
  }>({
    email: '',
    password: '',
  });

  const [ getAccessToken, { isLoading }] = useGetAccessTokenMutation();
  const dispatch = useAppDispatch();

  const handleLogin = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const response: any = await getAccessToken({
      email: form.email,
      password: form.password
    });

    if (response.data) {
      localStorage.setItem('access_token', response.data.access_token);
      dispatch(updateAccessToken(response.data.access_token));
    } else {
      alert(response.error.data.message);
    }
  };

  return (
      <>
        <div className="min-h-full flex h-screen">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="POST" className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onKeyUp={(event) => updateForm({ ...form, email: (event.target as HTMLInputElement).value})}
                        />
                      </div>
                    </div>
  
                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onKeyUp={(event) => updateForm({ ...form, password: (event.target as HTMLInputElement).value})}
                        />
                      </div>
                    </div>
  
                    <div className="flex items-center justify-between">  
                      <div className="text-sm">
                        <Link to="/password/reset" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleLogin}
                        disabled={isLoading}
                      >
                        { isLoading ? 'Loading...' : 'Sign in'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 bg-black">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </>
  );
}