import { Redirect } from "react-router"
import { useAppDispatch } from "../../../shared/redux/hooks";
import { updateAccessToken } from "../slice";

export const SignOut = () => {
    const dispatch = useAppDispatch();

    localStorage.removeItem('access_token');
    dispatch(updateAccessToken(undefined));
    
    return (
        <Redirect to="/login" />
    )
}