import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid';

interface PropTypes {
    heading: string;
    description?: string;
    createCallBack?: Function;
    editCallBack?: Function;
}

export const TabHeader = ({ heading, description, createCallBack, editCallBack }: PropTypes) => {
    return (
        <div className="px-4 py-5 sm:px-6">
            <div className="grid grid-flow-row auto-rows-max">
                <div className="col-start-1 col-end-8">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        { heading }
                    </h2>
                    {
                        description && (
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">{ description }</p>
                        )
                    }
                </div>
                <div className="col-start-11 col-end-12">
                {
                    createCallBack && (
                        <button
                            type="button"
                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-4"
                            onClick={() => createCallBack()}
                            >
                            <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
                            Create
                        </button>
                    )
                }
                {
                    editCallBack && (
                        <button
                            type="button"
                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-4"
                            onClick={() => editCallBack()}
                            >
                            <PencilIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                            Edit
                        </button>
                    )
                }
                </div>
            </div>
        </div>
    )
}