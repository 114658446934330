import React, { useState } from "react"
import { useUpdateUserPasswordMutation } from "../../../../api/user/api";


export const Password = () => {
    const [ form, updateForm ] = useState<{
        existing: string;
        new: string;
        new_confirmation: string;
    }>({
        existing: '',
        new: '',
        new_confirmation: '',
    });
    const [ updateUserPassword, { isLoading } ] = useUpdateUserPasswordMutation();
    const [ status, updateStatus ] = useState<{
        show: boolean;
        message: string;
    }>({
        show: false,
        message: '',
    });
    const [ formErrors, updateFormErrors ] = useState<{
        existing: string;
        new: string;
    }>({
        existing: '',
        new: '',
    })

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        try {
            updateFormErrors({ ...formErrors, ...{
                existing: '',
                new: '',
            } });

            const response: any = await updateUserPassword(form);
            
            if (response.error) {
                if (response.error.status === 422) {
                    const clone = { 
                        existing: formErrors.existing,
                        new: formErrors.new,
                     };
                    Object.keys(response.error.data.errors).forEach((value: string) => {
                        if (value === 'existing' || value === 'new') {
                            clone[value] = response.error.data.errors[value][0];
                        }
                    });

                    updateFormErrors({ ...formErrors, ...clone });

                    updateStatus({ ...status, ...{
                        show: true,
                        message: response.error.data.message,
                    }});
                } else {
                    updateStatus({ ...status, ...{
                        show: true,
                        message: "An error occured",
                    }});
                }
            } else {
                updateStatus({ ...status, ...{
                    show: true,
                    message: 'Successfully updated your password!',
                }});
            }
        } catch {
            console.log('hello')
        }
    }

    return (
        <form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
            {/* Password section */}
            <div className="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Password</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Update your existing password to something new.
                    </p>
                </div>

                <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-9">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Existing Password
                        </label>
                        <input
                            type="text"
                            name="existing"
                            id="existing"
                            autoComplete="off"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                          onKeyUp={(event) => updateForm({ ...form, existing: (event.target as HTMLInputElement).value})}
                        />
                        { formErrors.existing.length > 0 && <p>{formErrors.existing}</p>}
                    </div>
                    <div className="col-span-12 sm:col-span-9">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            New Password
                        </label>
                        <input
                            type="text"
                            name="new"
                            id="new"
                            autoComplete="off"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            onKeyUp={(event) => updateForm({ ...form, new: (event.target as HTMLInputElement).value})}
                        />
                        { formErrors.new.length > 0 && <p>{formErrors.new}</p>}
                    </div>
                    <div className="col-span-12 sm:col-span-9">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Confirm New Password
                        </label>
                        <input
                            type="text"
                            name="new_confirmation"
                            id="new_confirmation"
                            autoComplete="off"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                            onKeyUp={(event) => updateForm({ ...form, new_confirmation: (event.target as HTMLInputElement).value})}
                        />
                    </div>
                </div>

                {/* Privacy section */}
                <div className="pt-6 divide-y divide-gray-200">
                    <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                        {
                            status.show && <p className="italic">{ status.message }</p>
                        }
                        <button
                            type="submit"
                            className="ml-4 bg-green-500 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            onClick={handleSubmit}
                        >
                        Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}