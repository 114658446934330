import { configureStore } from "@reduxjs/toolkit";
import { allergyAPI } from "../../api/allergies/api";
import { authApi } from "../../api/auth/api";
import { companyAPI } from "../../api/company/api";
import { dietAPI } from "../../api/diets/api";
import { speakerEventAPI } from "../../api/speakerEvent/api";
import { userApi } from "../../api/user/api";
import { authSlice } from "../../features/auth/slice";
import { userSlice } from "../../features/user/slice";
import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [speakerEventAPI.reducerPath]: speakerEventAPI.reducer,
        [companyAPI.reducerPath]: companyAPI.reducer,
        [allergyAPI.reducerPath]: allergyAPI.reducer,
        [dietAPI.reducerPath]: dietAPI.reducer,
        auth: authSlice.reducer,
        user: userSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
        }).concat([
            unauthenticatedMiddleware,
            authApi.middleware,
            userApi.middleware,
            speakerEventAPI.middleware,
            companyAPI.middleware,
            allergyAPI.middleware,
            dietAPI.middleware,
        ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;