import { BrowserRouter as Router } from "react-router-dom";
import { Auth } from "./features/auth/Auth";
import { UserMiddleware } from "./features/auth/components/UserMiddleware";
import './index.css';
import { Dashboard } from "./shared/components/Dashboard";

function App() {
  return (
    <Router>
      <UserMiddleware>
        <Auth />
        <Dashboard />
      </UserMiddleware>
    </Router>
  );
}

export default App;
