import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../shared/redux/store";
import { AuthState, UpdateAccessTokenPayload } from "./types";

const initialState: AuthState = {
    access_token: localStorage.getItem('access_token') ?? undefined,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAccessToken: (state, action: PayloadAction<any>) => {
            state.access_token = action.payload;
        },
    },
});

export const { updateAccessToken } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.access_token;

export default authSlice.reducer;