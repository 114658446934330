import { Switch, Route } from "react-router-dom"
import { Login } from "./components/Login"
import { ResetPassword } from "./components/ResetPassword"
import { ResetPasswordEmail } from "./components/ResetPasswordEmail"
import { SignOut } from "./components/SignOut"

export const Auth = () => {
    return (
        <Switch>
            <Route path="/login" exact>
                <Login />
            </Route>
            <Route path="/signout" exact>
                <SignOut />
            </Route>
            {/* Form to send password reset notification */}
            <Route path="/password/reset" exact>
                <ResetPasswordEmail />
            </Route>
            {/* Form to reset password */}
            <Route path="/password/reset/:token" exact>
                <ResetPassword />
            </Route>
        </Switch>
    )
}