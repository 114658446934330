interface PropTypes {
    name: string;
    label: string;
    defaultValue?: string;
    onSelectCallback: Function;
    disabled?: boolean;
    description?: string;
    values: Array<{
        id: number,
        value: string,
        description?: string,
    }>;
    selected: Array<number>;
    error?: string
}

export const SlideOverFormCheckbox = ({ name, label, defaultValue, onSelectCallback, disabled, description, values, selected, error}: PropTypes) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).value;
      onSelectCallback(value);
    }

    return (
        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
                <label
                htmlFor="project-name"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                >
                    {label}
                </label>
            </div>
            <div className="mt-4 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg space-y-4">
                    {
                        values && values.map(value => (
                            <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                        value={value.id}
                                        checked={selected.includes(value.id)}
                                        onChange={handleChange}
                                />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="comments" className="font-medium text-gray-700">
                                        {value.value}
                                    </label>
                                    {
                                        value.description && (
                                            <p className="text-gray-500">{ value.description }</p>
                                        )
                                    }
                                </div>       
                            </div>
                        ))
                    }
                </div>
                {
                    error && (
                    <p className="text-red-500">{ error }</p>
                    )
                }
            </div>
        </div>
    )
}