import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useState } from "react"

interface PropTypes {
    name: string;
    label: string;
    defaultValue?: string;
    onKeyUpCallback: Function;
    disabled?: boolean;
    description?: string;
    error?: string;
}

export const SlideOverFormInput = ({ name, label, defaultValue, onKeyUpCallback, disabled, description, error }: PropTypes) => {
  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onKeyUpCallback(value);
  }

  return (
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
          >
            {label}
          </label>
        </div>
        <div className="sm:col-span-2">
          <input
            type="text"
            name={name}
            id="project-name"
            className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
            defaultValue={defaultValue ?? ''}
            onKeyUp={handleChange}
            disabled={disabled}
          />
          { description && (
            <>
              <hr className="border-gray-200" />
              <div className="flex flex-col space-between space-y-4 sm:flex-row sm:items-center sm:space-between sm:space-y-0">
                <div>
                  <a
                      href="#"
                      className="group flex items-center text-sm text-gray-500 hover:text-gray-900 space-x-2.5"
                  >
                    <QuestionMarkCircleIcon
                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                    <span>{ description }</span>
                  </a>
                </div>
              </div>
            </>
          )}
          {
            error && (
              <p className="text-red-500">{ error }</p>
            )
          }
        </div>
      </div>
  )
}