import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthBody, AuthResponse, PasswordResetResponse, PasswordResetBody, PasswordResetEmailBody, PasswordResetEmailResponse } from './types';

export const AUTH_API_REDUCER_KEY = 'authAPI';
export const authApi = createApi({
    reducerPath: AUTH_API_REDUCER_KEY,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            headers.set('accept', 'application/json');

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAccessToken: builder.mutation<AuthResponse, AuthBody>({
            query: (credentials) => {
                return ({
                    url: 'authenticate',
                    method: 'POST',
                    body: credentials,
                });
            },
        }),
        resetPasswordEmail: builder.mutation<PasswordResetEmailResponse, PasswordResetEmailBody>({
            query: (body) => {
                return ({
                    url: 'password/email',
                    method: 'POST',
                    body,
                })
            }
        }),
        resetPassword: builder.mutation<PasswordResetResponse, PasswordResetBody>({
            query: (body) => {
                return ({
                    url: 'password/reset',
                    method: 'PUT',
                    body,
                })
            }
        })
    }),
});

export const { useGetAccessTokenMutation, useResetPasswordEmailMutation, useResetPasswordMutation } = authApi;