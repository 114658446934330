import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'inspector';
import { RootState } from '../../shared/redux/store';
import { SendList, SpeakerEvent, SpeakerEventCostBody, SpeakerEventCostDeleteBody, SpeakerEventDetails422, SpeakerEventDetailsBody, SpeakerEventEmailBody, SpeakerEventEmailSendList, SpeakerEventGuest, SpeakerEventGuest422, SpeakerEventGuestBody, SpeakerEventGuestDeleteBody, SpeakerEventOverview } from './types';

export const SPEAKER_EVENT_REDUCER_KEY = 'speakerEventAPI';
export const speakerEventAPI = createApi({
    reducerPath: SPEAKER_EVENT_REDUCER_KEY,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL as string,
        prepareHeaders: (headers, { getState }) => {
            headers.set('accept', 'application/json');
            headers.set('authorization', `Bearer ${(getState() as RootState).auth.access_token}`)

            return headers;
        },
    }),
    tagTypes: ['SpeakerEvent', 'Guests', 'SendList'],
    endpoints: (builder) => ({
        fetchAllSpeakerEvents: builder.query<Array<SpeakerEventOverview>, null>({
            query: () => ({
                url: "speaker-events",
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { data: Array<SpeakerEventOverview> }) => response.data,
            providesTags: ['SpeakerEvent'],
        }),
        addSpeakerEvent: builder.mutation<SpeakerEventOverview, Omit<SpeakerEventOverview, 'id'>>({
            query: (body) => ({
                url: "speaker-events",
                method: "POST",
                body,
            }),
            invalidatesTags: ['SpeakerEvent']
        }),
        getSpeakerEvent: builder.query<SpeakerEvent, string>({
            query: (id) => `speaker-events/${id}`,
            transformResponse: (response: { data: SpeakerEvent }) => response.data,
            providesTags: (result, error, id) => [{ type: 'SpeakerEvent', id }],
        }),
        updateSpeakerEvent: builder.mutation<null | SpeakerEventDetails422, SpeakerEventDetailsBody>({
            query: (body) => ({
                url: `speaker-events/${body.id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['SpeakerEvent'],
        }),
        updateSpeakerEventguest: builder.mutation<SpeakerEventGuest422 | null, SpeakerEventGuestBody>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/guests/${body.id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['SpeakerEvent', 'Guests', 'SendList'],
        }),
        addSpeakerEventGuest: builder.mutation<SpeakerEventGuest422 |null, Omit<SpeakerEventGuestBody, 'id'>>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/guests`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['SpeakerEvent', 'SendList'],
        }),
        removeSpeakerEventGuest: builder.mutation<null, SpeakerEventGuestDeleteBody>({
            query: (body) => ({
                url:  `speaker-events/${body.dinnerId}/guests/${body.guestId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SpeakerEvent', 'Guests'],
        }),
        addSpeakerEventCost: builder.mutation<null, Omit<SpeakerEventCostBody, 'id'>>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/costs`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['SpeakerEvent'],
        }),
        updateSpeakerEventCost: builder.mutation<null, SpeakerEventCostBody>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/costs/${body.id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['SpeakerEvent'],
        }),
        removeSpeakerEventCost: builder.mutation<null, SpeakerEventCostDeleteBody>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/costs/${body.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SpeakerEvent'],
        }),
        updateSpeakerEventEmail: builder.mutation<null, SpeakerEventEmailBody>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/emails/${body.id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['SpeakerEvent'],
        }),
        fetchGuests: builder.query<Array<SpeakerEventGuest>, { dinnerId: number, query: string }>({
            query: (body) => ({
                url: `speaker-events/${body.dinnerId}/guests/search?query=${body.query}`
            }),
            providesTags: ['Guests'],
            transformResponse: (response: any) => response.data,
        }),
        fetchEmailSendList: builder.query<Array<SendList>, SpeakerEventEmailSendList>({
            query: ({ event_id, email_id }) => ({
                url: `speaker-events/${event_id}/emails/${email_id}/sendList`,
            }),
            transformResponse: (response: { data: Array<SendList> }) => response.data,
            keepUnusedDataFor: 1,
            providesTags: ['SendList'],
        }),
        sendEmail: builder.mutation<null, { speakerEventId: number, emailId: number}>({
            query: ({ speakerEventId, emailId }) => ({
                url: `speaker-events/${speakerEventId}/emails/${emailId}/send`,
                method: 'POST',
            }),
            invalidatesTags: ['SendList'],
        }),
    }),
});

export const { 
    useFetchAllSpeakerEventsQuery, 
    useGetSpeakerEventQuery, 
    useUpdateSpeakerEventMutation,
    useUpdateSpeakerEventguestMutation,
    useAddSpeakerEventGuestMutation,
    useRemoveSpeakerEventGuestMutation,
    useAddSpeakerEventCostMutation,
    useUpdateSpeakerEventCostMutation,
    useRemoveSpeakerEventCostMutation,
    useUpdateSpeakerEventEmailMutation,
    useFetchGuestsQuery,
    useAddSpeakerEventMutation,
    useFetchEmailSendListQuery,
    useSendEmailMutation,
} = speakerEventAPI;