import { Dialog, Transition } from '@headlessui/react'
import { Component, Fragment } from 'react'

interface propTypes {
    show: boolean;
    close: Function;
    title: string;
    reject: {
        show: boolean;
        value: string | undefined;
        callback: Function | undefined;
    };
    accept: {
        show: boolean;
        value: string | undefined;
        callback: Function | undefined;
    }
}

export const Modal = ({ show, close, title, reject, accept }: propTypes) => {
    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto bg-smoke-light"
                onClose={() => close(false)}
            >
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
                >
                &#8203;
                </span>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                    >
                    { title }
                    </Dialog.Title>
                    <div className="mt-2">
                        {/* { body } */}
                    </div>

                    <div className="mt-4">
                        { reject.show &&
                            <button
                                type="button"
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                onClick={() => reject.callback && reject.callback()}
                            >
                                { reject.value }
                            </button>
                        }
                        { accept.show &&
                            <button
                                type="button"
                                className=" ml-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                onClick={() => accept.callback && accept.callback()}
                            >
                                { accept.value }
                            </button>
                        }
                    </div>
                </div>
                </Transition.Child>
            </div>
            </Dialog>
        </Transition>
    )
}