import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SlideOverFormInput } from '../../../shared/components/slideOver/FormInput'
import { SlideOverPrivacy } from '../../../shared/components/slideOver/Privacy'
import { SlideOverHeader } from '../../../shared/components/slideOver/Header'
import { SlideOverDatepicker } from '../../../shared/components/slideOver/Datepicker'
import { EmployeeSearch } from '../../../shared/components/EmployeeSearch'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useAddSpeakerEventMutation, useFetchAllSpeakerEventsQuery } from '../../../api/speakerEvent/api'
import { SpeakerEventCreate422 } from '../../../api/speakerEvent/types'

const people = [
  { id: 1, name: 'Jane Cooper', location: 'Regional Paradigm Technician', role: 'Admin', date: 'jane.cooper@example.com' },
  { id: 2, name: 'Cody Fisher', location: 'Product Directives Officer', role: 'Owner', date: 'cody.fisher@example.com' },
  // More people...
]

const breadcrumbs = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
]

export const SpeakerEventList = () => {
  const [ modal, setOpenModal ] = useState(false);
  const { data, error, isLoading } = useFetchAllSpeakerEventsQuery(null);
  const [ createForm, updateCreateForm ] = useState<{
    name: string,
    location: string,
    date: Date,
  }>({
    name: '',
    location: '',
    date: new Date(),
  });
  const [ addSpeakerEvent] = useAddSpeakerEventMutation();
  const [ errorState, updateErrorState ] = useState<{
    name: string,
    location: string,
    date: string,
  }>({
    name: '',
    location: '',
    date: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const response: any = await addSpeakerEvent({
      name: createForm.name,
      location: createForm.location,
      date: createForm.date.toUTCString(),
    });

    if (response.error) {
        var current = errorState;
        const error = response.error.data as SpeakerEventCreate422;
        Object.keys(error.errors).forEach(key => {
            let e = '';
            switch (key) {
                case 'name':
                    e = error.errors.name && error.errors.name.length > 0 ? error.errors.name[0] : '';

                    current.name = e;
                    break;
                case 'location':
                    e = error.errors.location && error.errors.location.length > 0 ? error.errors.location[0] : '';

                    current.location = e;
                    break;
                case 'date':
                    e = error.errors.date && error.errors.date.length > 0 ? error.errors.date[0] : '';

                    current.date = e;
                    break;
            }
        })

        updateErrorState(current);

        return
      }

      setOpenModal(false);
      updateCreateForm({
        name: '',
        location: '',
        date: new Date(),
      });
  }

    return (
        <div>
          <div className="relative bg-sky-700 pb-32 overflow-hidden bg-terrapin-black">
            <header className="relative py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className='grid grid-cols-5 gap-4'>
                    <div className='col-span-4'>
                      <h1 className="text-3xl font-bold text-white">Speaker Events</h1>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-4"
                        onClick={() => setOpenModal(true)}
                      >
                        <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
                        Create
                      </button>
                    </div>
                  </div>
                </div>
            </header>
          </div>
          {error ? (
            <>Oh no, there was an error</>
          ) : isLoading ? (
            <>Loading...</>
          ) : data ? (
            <main className="relative -mt-32">
              <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
                <div className="bg-white rounded-lg shadow overflow-hidden">
  
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  location
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  date
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Last Update
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((event, eventIdx) => (
                                <tr key={event.date} className={eventIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                  <Link to={`/speakerevent/${event.id}`} className="text-indigo-600 hover:text-indigo-900">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{event.name}</td>
                                  </Link>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{event.location}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{event.date}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment().format('MMMM Do YYYY, h:mm:ss a')}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <Link to={`/speakerevent/${event.id}`} className="text-indigo-600 hover:text-indigo-900">
                                      Edit
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

          ) : null }
          <Transition.Root show={modal} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => setOpenModal(false)}>
              <div className="absolute inset-0 overflow-hidden">
                <Dialog.Overlay className="absolute inset-0" />

                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <div className="w-screen max-w-2xl">
                      <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                        <div className="flex-1">
                          {/* Header */}
                          <SlideOverHeader
                            title='Create a Speaker Event'
                            description='Get started by filling in the information below to create your new speaker event.'
                            closeCallback={() => setOpenModal(false)}
                          />

                          {/* Divider container */}
                          <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            {/* Project name */}
                            <SlideOverFormInput
                              name='name'
                              label='Name'
                              onKeyUpCallback={(name: string) => {
                                updateCreateForm({ ...createForm, name: name })
                                updateErrorState({ ...errorState, name: '' })
                              }}
                              error={errorState.name}
                            />

                            {/* Project description */}
                            <SlideOverFormInput
                              name='location'
                              label='Location'
                              onKeyUpCallback={(location: string) => {
                                updateCreateForm({ ...createForm, location })
                                updateErrorState({ ...errorState, location: '' })
                              }}
                              error={errorState.location}
                            />

                            {/* Project dae */}
                            <SlideOverDatepicker
                              name='date'
                              label='Date'
                              changeCallback={(date: Date) => {
                                updateCreateForm({ ...createForm, date })
                                updateErrorState({ ...errorState, date: '' })
                              }}
                              error={errorState.date}
                            />

                            {/* Team members */}
                            {/* <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                              <div>
                                <h3 className="text-sm font-medium text-gray-900">Team Members</h3>
                              </div>
                              <div className="sm:col-span-2">
                                <EmployeeSearch />
                              </div>
                            </div> */}

                            {/* Privacy */}
                            {/* <SlideOverPrivacy /> */}
                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                          <div className="space-x-3 flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => setOpenModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={handleSubmit}
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
    )
}