/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import { SpeakerEventGuests } from './SpeakerEventGuests'
import { SpeakerEventCosts } from './SpeakerEventCosts'
import { useGetSpeakerEventQuery } from '../../../api/speakerEvent/api'
import { SpeakerEvent } from '../../../api/speakerEvent/types'
import { SpeakerEventEmails } from './SpeakerEventEmails'
import { Breadcrumbs } from '../../../shared/components/breadcrumbs'
import { SpeakerEventDetails } from './SpeakerEventDetails'

const user = {
  name: 'Whitney Francis',
  email: 'whitney@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Dashboard', href: '#' },
  { name: 'Jobs', href: '#' },
  { name: 'Applicants', href: '#' },
  { name: 'Company', href: '#' },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]
const attachments = [
  { name: 'resume_front_end_developer.pdf', href: '#' },
  { name: 'coverletter_front_end_developer.pdf', href: '#' },
]
const comments = [
  {
    id: 1,
    name: 'Leslie Alexander',
    date: '4d ago',
    imageId: '1494790108377-be9c29b29330',
    body:
      'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
  },
  {
    id: 2,
    name: 'Michael Foster',
    date: '4d ago',
    imageId: '1519244703995-f4e0f30006d5',
    body:
      'Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.',
  },
  {
    id: 3,
    name: 'Dries Vincent',
    date: '4d ago',
    imageId: '1506794778202-cad84cf45f1d',
    body:
      'Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.',
  },
]
const publishingOptions = [
  { name: 'Published', description: 'This job posting can be viewed by anyone who has the link.', current: true },
  { name: 'Draft', description: 'This job posting will no longer be publicly accessible.', current: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const SpeakerEventView = () => {
    const [selected, setSelected] = useState('Details')
    const { id } = useParams<{
        id?: string;
    }>();

    const { data, error, isLoading, isSuccess } = useGetSpeakerEventQuery(id as string);


    const breadcrumbs = [
        { name: 'Speaker Events', href: '/speakerevent', current: false },
        { name: String(data?.name), href: '', current: true },
    ]

    return (
        <div>
            { isSuccess && (
                <>
                <div className="relative bg-sky-700 pb-32 overflow-hidden bg-terrapin-black">
                <header className="relative py-10">
                    <Breadcrumbs crumbs={breadcrumbs} />
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className='grid grid-cols-5 gap-4'>
                        <div className='col-span-4'>
                        <h1 className="text-3xl font-bold text-white">Speaker Events - {data?.name}</h1>
                        </div>
                    </div>
                    </div>
                </header>
                </div>
                <main className="relative -mt-32">
                    <Tab.Group>
                        <div className='mx-auto grid w-1/3'>
                        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl bg-terrapin-black-700">
                            <Tab
                                key={'Details'}
                                className={({ selected }) =>
                                    classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'text-black shadow bg-white '
                                        : 'text-blue-100 hover:bg-terrapin-black-500 hover:text-white bg-terrapin-black-900'
                                    )
                                }
                            >
                                Details
                            </Tab>
                            <Tab
                                key={'Guests'}
                                className={({ selected }) =>
                                    classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-terrapin-black-500 hover:text-white bg-terrapin-black-900'
                                    )
                                }
                            >
                                Guests
                            </Tab>
                            <Tab
                                key={'Emails'}
                                className={({ selected }) =>
                                    classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-terrapin-black-500 hover:text-white bg-terrapin-black-900'
                                    )
                                }
                            >
                                Emails
                            </Tab>
                            <Tab
                                key={'Costs'}
                                className={({ selected }) =>
                                    classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-terrapin-black-500 hover:text-white bg-terrapin-black-900'
                                    )
                                }
                            >
                                Costs
                            </Tab>
                        </Tab.List>
                        </div>
                        <div className='w-full'>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel
                                key={'Details'}
                            >
                                <SpeakerEventDetails speakerEvent={data as SpeakerEvent}/>
                            </Tab.Panel>
                            <Tab.Panel
                                key={'Guests'}
                            >
                                <SpeakerEventGuests speakerEvent={data as SpeakerEvent}/>
                            </Tab.Panel>
                            <Tab.Panel
                                key={'Emails'}
                            >
                                <SpeakerEventEmails speakerEvent={data as SpeakerEvent}/>
                            </Tab.Panel>
                            <Tab.Panel
                                key={'Costs'}
                            >
                                <SpeakerEventCosts speakerEvent={data as SpeakerEvent}/>
                            </Tab.Panel>
                        </Tab.Panels>
                        </div>
                    </Tab.Group>
                </main>
                </>
            )}
        </div>
    )
}