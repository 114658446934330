import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { forwardRef, useState } from "react";
import moment from 'moment'

interface PropTypes {
    label: string;
    name: string;
    changeCallback: Function;
    value?: string;
    error?: string;
}

export const SlideOverDatepicker = ({ label, name, changeCallback, value, error }: PropTypes) => {
    const [startDate, setStartDate] = useState<Date>(value ? new Date(value) : new Date())
    
    return (
        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              {label}
            </label>
          </div>
          <div className="sm:col-span-2">
            <DatePicker
                name={name}
                selected={startDate}
                onChange={(date: Date) => {
                    setStartDate(date);
                    changeCallback(date)
                }}
                customInput={<ButtonInput />}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className="flex items-center justify-between px-2 py-2">
                        <span className="text-lg text-gray-700">
                            {format(date, 'MMMM yyyy, h:mm:ss a')}
                        </span>

                        <div className="space-x-2">
                            <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                type="button"
                                className={`
                                    ${prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                                    inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                `}
                            >
                                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                            </button>

                            <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                type="button"
                                className={`
                                    ${nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                                    inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                `}
                            >
                                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                            </button>
                        </div>
                    </div>
                )}
                showTimeSelect
            />
            {
                error && (
                    <p className="text-red-500">{ error }</p>
                )
            }
          </div>
        </div>
    )
}

const ButtonInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button
        onClick={onClick}
        ref={ref}
        type="button"
        className='inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500'
    >
        {format(new Date(value), 'dd MMMM yyyy')}
    </button>
))