import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../shared/redux/store';
import { Allergy } from './types';

export const allergyAPI = createApi({
    reducerPath: 'allergyAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL as string,
        prepareHeaders: (headers, { getState }) => {
            headers.set('accept', 'application/json');
            headers.set('authorization', `Bearer ${(getState() as RootState).auth.access_token}`)

            return headers;
        },
    }),
    tagTypes: ['Allergy'],
    endpoints: (builder) => ({
        fetchAllAllergies: builder.query<Array<Allergy>, null>({
            query: () => ({
                url: "allergies",
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { data: Array<Allergy> }) => response.data,
            providesTags: ['Allergy'],
        }),
    }),
});

export const { useFetchAllAllergiesQuery } = allergyAPI;