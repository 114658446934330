import { Route, Router, Switch } from "react-router"
import { SpeakerEventList } from "../../features/speakerEvent/components/SpeakerEventList"
import { SpeakerEventView } from "../../features/speakerEvent/components/SpeakerEventView"
import { Settings } from "../../features/user/components/Settings/Settings"
import { Navigation } from "./Navigation"

export const Dashboard = () => {
    const navigation = [
        { name: 'Dashboard', href: '/' },
        { name: 'Speaker Events', href: '/speakerevent' },
    ]

    return (
        <div>
            <Navigation 
                navigation={navigation}
            />
            <Switch>
                <Route path="/settings" exact>
                    <Settings />
                </Route>
                <Route path="/speakerevent" exact>
                    <SpeakerEventList />
                </Route>
                <Route path="/speakerevent/:id" exact>
                    <SpeakerEventView />
                </Route>
            </Switch>
        </div>
    )
}