import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../shared/redux/store";
import { User, UserState } from "./types";

const initialState: UserState = {
    user: undefined,
    loaded: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
    },
});

export const { updateUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;