import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query"
import { RootState } from "../shared/redux/store"

export const defaultBaseQuery = () => {
    return fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.access_token;
            headers.set('authorization', `Bearer ${token}`);
            headers.set('accept', 'application/json');

            return headers;
        },
    })
}