import { HomeIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

interface PropTypes {
    crumbs: Array<{
        name: string,
        href: string,
        current: boolean,
    }>
};

export const Breadcrumbs = ({ crumbs }: PropTypes) => {
    return (
        <nav className="hidden mb-8 lg:flex" aria-label="Breadcrumb">
            <ol role="list" className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
                <li className="flex">
                <div className="flex items-center">
                    <Link to="/" className="text-gray-400 hover:text-white">
                        <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Home</span>
                    </Link>
                </div>
                </li>
                {crumbs.map((item) => (
                <li key={item.name} className="flex">
                    <div className="flex items-center">
                    <svg
                        className="flex-shrink-0 w-6 h-full text-gray-200"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 44"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                    >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <Link
                        to={item.href}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={item.current ? 'page' : undefined}
                    >
                        {item.name}
                    </Link>
                    </div>
                </li>
                ))}
            </ol>
        </nav>
    );
}