import { SpeakerEvent, SpeakerEventDetails422 } from "../../../api/speakerEvent/types";
import { Timeline } from "../../../shared/components/Timeline"
import {
  CheckIcon,
  PencilIcon,
  ThumbUpIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { SlideOverHeader } from "../../../shared/components/slideOver/Header";
import { SlideOverFormInput } from "../../../shared/components/slideOver/FormInput";
import { SlideOverDatepicker } from "../../../shared/components/slideOver/Datepicker";
import { useUpdateSpeakerEventMutation } from "../../../api/speakerEvent/api";
import { TabHeader } from "../../../shared/components/tabs/TabHeader";

const eventTypes = {
    applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
    advanced: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
    completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
  }

const timeline = [
    {
      id: 1,
      type: eventTypes.applied,
      content: 'Applied to',
      target: 'Front End Developer',
      date: 'Sep 20',
      datetime: '2020-09-20',
    },
    {
      id: 2,
      type: eventTypes.advanced,
      content: 'Advanced to phone screening by',
      target: 'Bethany Blake',
      date: 'Sep 22',
      datetime: '2020-09-22',
    },
    {
      id: 3,
      type: eventTypes.completed,
      content: 'Completed phone screening with',
      target: 'Martha Gardner',
      date: 'Sep 28',
      datetime: '2020-09-28',
    },
    {
      id: 4,
      type: eventTypes.advanced,
      content: 'Advanced to interview by',
      target: 'Bethany Blake',
      date: 'Sep 30',
      datetime: '2020-09-30',
    },
    {
      id: 5,
      type: eventTypes.completed,
      content: 'Completed interview with',
      target: 'Katherine Snyder',
      date: 'Oct 4',
      datetime: '2020-10-04',
    },
  ]

interface PropTypes {
    speakerEvent: SpeakerEvent;
};

export const SpeakerEventDetails = ({ speakerEvent }: PropTypes) => {
    const [ modal, setOpenModal ] = useState(false);
    const [ state, updateState ] = useState<{
        name: string;
        location: string;
        date: string;
    }>({
        name: speakerEvent.name,
        location: speakerEvent.location,
        date: speakerEvent.date,
    });
    const [ updateEventDetails ] = useUpdateSpeakerEventMutation();
    const [ errorState, updateErrorState ] = useState<{
        name: string;
        location: string;
        date: string;
    }>({
        name: '',
        location: '',
        date: '',
    });

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();

        const response: any = await updateEventDetails({
            id: speakerEvent.id,
            name: state.name,
            location: state.location,
            date: state.date,
        });

        if (response.error) {
            var current = errorState;
            const error = response.error.data as SpeakerEventDetails422;
            Object.keys(error.errors).forEach(key => {
                let e = '';
                switch (key) {
                    case 'name':
                        e = error.errors.name && error.errors.name.length > 0 ? error.errors.name[0] : '';

                        current.name = e;
                        break;
                    case 'location':
                        e = error.errors.location && error.errors.location.length > 0 ? error.errors.location[0] : '';

                        current.location = e;
                        break;
                    case 'date':
                        e = error.errors.date && error.errors.date.length > 0 ? error.errors.date[0] : '';

                        current.date = e;
                        break;
                }
            })
            updateErrorState(current);

            return
        }

        setOpenModal(false)
    }

    return (
        <div className="mt-8 max-w-4xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <section aria-labelledby="applicant-information-title" className="space-y-6 lg:col-start-1 lg:col-span-2">
                <div className="bg-white shadow sm:rounded-lg h-full">
                    <TabHeader
                        heading="Dinner Information"
                        description="Overview of dinner."
                        editCallBack={() => setOpenModal(true)}
                    />
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                <dd className="mt-1 text-sm text-gray-900">{speakerEvent?.name}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Location</dt>
                                <dd className="mt-1 text-sm text-gray-900">{speakerEvent?.location}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{speakerEvent?.date}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                    Timeline
                    </h2>

                    {/* Activity Feed */}
                    <Timeline 
                        timeline={timeline}
                    />
                </div>
            </section>
        <Transition.Root show={modal} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => setOpenModal(false)}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                        <div className="w-screen max-w-2xl">
                            <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <div className="flex-1">
                                    {/* Header */}
                                    <SlideOverHeader
                                        title={'Edit the Speaker Event Details'}
                                        description={`Get started by filling in the information below to update the details.`}
                                        closeCallback={() => setOpenModal(false)}
                                    />

                                    <SlideOverFormInput
                                        name="name"
                                        label="name"
                                        onKeyUpCallback={(val: string) => {
                                            updateState({ ...state, name: val })
                                            updateErrorState({ ...errorState, name: '' })
                                        }}
                                        defaultValue={state.name}
                                        error={errorState.name}
                                    />

                                    <SlideOverFormInput
                                        name="location"
                                        label="location"
                                        onKeyUpCallback={(val: string) => {
                                            updateState({ ...state, location: val })
                                            updateErrorState({ ...errorState, location: '' })
                                        }}
                                        defaultValue={state.location}
                                        error={errorState.location}
                                    />

                                    <SlideOverDatepicker
                                        name="date"
                                        label="Date"
                                        changeCallback={(val: string) => {
                                            updateState({ ...state, date: val })
                                            updateErrorState({ ...errorState, date: '' })
                                        }}
                                        value={state.date}
                                        error={errorState.date}
                                    />
                                </div>

                                {/* Action buttons */}
                                <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                    <div className="space-x-3 flex justify-end">
                                        <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => setOpenModal(false)}
                                        >
                                        Cancel
                                        </button>
                                        <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={handleFormSubmit}
                                        >
                                        Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        </div>
    )
}