import { useState } from 'react'
import {
  KeyIcon,
  UserCircleIcon,
} from '@heroicons/react/outline'
import { Profile } from './Profile'
import { Password } from './Password'

const user = {
  name: 'Debbie Lewis',
  handle: 'deblewis',
  email: 'debbielewis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
}

const subNavigation = [
  { name: 'Profile', href: '#', icon: UserCircleIcon, current: true },
  { name: 'Password', href: '#password', icon: KeyIcon, current: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const Settings = () => {
  const [ tab, updateTab ] = useState<'profile' | 'password'>('profile');

    return (
        <div>
          <div className="relative bg-sky-700 pb-32 overflow-hidden bg-terrapin-black">
            <header className="relative py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-white">Settings</h1>
                </div>
            </header>
          </div>
    
          <main className="relative -mt-32">
            <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
              <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                  <aside className="py-6 lg:col-span-3">
                    <nav className="space-y-1">
                      <a
                        key={'Profile'}
                        onClick={() => updateTab('profile')}
                        className={classNames(
                          tab === 'profile'
                            ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                            : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                          'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        aria-current={tab === 'profile' ? 'page' : undefined}
                      >
                        <UserCircleIcon
                          className={classNames(
                            tab === 'profile'
                              ? 'text-teal-500 group-hover:text-teal-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{'Profile'}</span>
                      </a>
                      <a
                        key={"Password"}
                        onClick={() => updateTab('password')}
                        className={classNames(
                          tab === 'password'
                            ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                            : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                          'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        aria-current={tab === 'password' ? 'page' : undefined}
                      >
                        <KeyIcon
                          className={classNames(
                            tab === 'password'
                              ? 'text-teal-500 group-hover:text-teal-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{"Password"}</span>
                      </a>
                    </nav>
                  </aside>
    
                  { tab === 'profile'
                    ? <Profile />
                    : (
                      <Password />
                    )
                  }
                </div>
              </div>
            </div>
          </main>
        </div>
    )
}