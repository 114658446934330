import { Dialog, Transition } from "@headlessui/react";
import { SpeakerEvent, SpeakerEventEmail, SpeakerEventEmail422 } from "../../../api/speakerEvent/types";
import { Fragment, useEffect, useState } from 'react';
import { SlideOverHeader } from "../../../shared/components/slideOver/Header";
import { SlideOverFormInput } from "../../../shared/components/slideOver/FormInput";
import { SlideOverFormEditor } from "../../../shared/components/slideOver/FormEditor";
import { TabHeader } from "../../../shared/components/tabs/TabHeader";
import { useFetchEmailSendListQuery, useSendEmailMutation, useUpdateSpeakerEventEmailMutation } from "../../../api/speakerEvent/api";
import { CheckIcon } from "@heroicons/react/solid";

interface PropTypes {
    speakerEvent: SpeakerEvent;
};

export const SpeakerEventEmails = ({ speakerEvent }: PropTypes) => {
    const [ editModalIsOpen, openEditModal ] = useState(false);
    const [ sendModalIsOpen, openSendModal ] = useState(false);
    const [ emailInFocus, updateEmailInFocus ] = useState(0);
    const [ editForm, updateEditForm ] = useState<SpeakerEventEmail>({
        id: 0,
        subject: '',
        body: '',
        reply_to_phone: '',
        reply_to_email: '',
    });
    const [ updateSpeakerEventEmail ] = useUpdateSpeakerEventEmailMutation();
    const [ errorState, updateErrorState ] = useState<Omit<SpeakerEventEmail, 'id'>>({
        subject: '',
        body: '',
        reply_to_phone: '',
        reply_to_email: '',
    });
    const { data: sendList, refetch } = useFetchEmailSendListQuery({
        email_id: emailInFocus,
        event_id: speakerEvent.id,
    }, {
        skip: emailInFocus === 0,
    });

    const [ sendEmail ] = useSendEmailMutation();
    const handleFormSubmit = async (event: any) => {
        event.preventDefault()

        const response: any = await updateSpeakerEventEmail({
            dinnerId: speakerEvent.id,
            id: editForm.id,
            subject: editForm.subject,
            body: editForm.body,
            reply_to_email: editForm.reply_to_email,
            reply_to_phone: editForm.reply_to_phone,
        });

        if (response.error) {
            var current = errorState;
            const error = response.error.data as SpeakerEventEmail422;
            Object.keys(error.errors).forEach(key => {
                let e = '';
                switch (key) {
                    case 'subject':
                        e = error.errors.subject && error.errors.subject.length > 0 ? error.errors.subject[0] : '';

                        current.subject = e;
                        break;
                    case 'body':
                        e = error.errors.body && error.errors.body.length > 0 ? error.errors.body[0] : '';

                        current.body = e;
                        break;
                    case 'reply_to_phone':
                        e = error.errors.reply_to_phone && error.errors.reply_to_phone.length > 0 ? error.errors.reply_to_phone[0] : '';

                        current.reply_to_phone = e;
                        break;
                    case 'reply_to_email':
                        e = error.errors.reply_to_email && error.errors.reply_to_email.length > 0 ? error.errors.reply_to_email[0] : '';

                        current.reply_to_email = e;
                        break;
                }
            })
            updateErrorState(current);

            return
        }

        closeModal();
    }

    const closeModal = () => {
        openEditModal(false)
        updateErrorState({
            subject: '',
            body: '',
            reply_to_phone: '',
            reply_to_email: '',
        })
    }

    const closeSendModal = () => {
        openSendModal(false)
        updateEmailInFocus(0);
    }

    return (
        <div className="mt-8 max-w-4xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-10"> 
            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white border-2 border-gray-100 shadow sm:rounded-lg">
                        <TabHeader
                            heading="Emails"
                            description="Emails for speaker event."
                        />
                        <div className="border-t border-gray-300 overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Subject
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Type
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                            <span className="sr-only">Send</span>
                                            <span className="sr-only">log</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { speakerEvent.emails.map((email, emailIdx) => (
                                        <tr key={email.id} className={emailIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ email.subject}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Invitation</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a 
                                                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                                                    onClick={() => {
                                                        updateEditForm(
                                                            email,
                                                        );

                                                        openEditModal(true);
                                                    }}
                                                >
                                                    Edit
                                                </a>
                                                <a 
                                                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                                                    onClick={() => {
                                                        openSendModal(true);
                                                        updateEmailInFocus(email.id);
                                                        console.log(email);
                                                    }}
                                                >
                                                    Send
                                                </a>
                                                <a 
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                    onClick={() => {
                                                        // console.log('hello world');
                                                    }}
                                                >
                                                    Log
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        <Transition.Root show={editModalIsOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => console.log('hello')}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                        <div className="w-screen max-w-2xl">
                            <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <div className="flex-1">
                                    {/* Header */}
                                    <SlideOverHeader
                                        title='Edit an Email'
                                        description={`Get started by filling in the information below to edit your email.`}
                                        closeCallback={() => closeModal()}
                                    />
                                    
                                    <SlideOverFormInput
                                        name={'subject'}
                                        label={'Subject'}
                                        defaultValue={editForm.subject}
                                        onKeyUpCallback={(value: string) => {
                                            updateEditForm({ ...editForm, subject: value })
                                            updateErrorState({ ...errorState, subject: '' })
                                        }}
                                        disabled={false}
                                        error={errorState.subject}
                                    />

                                    <SlideOverFormEditor
                                        name={'body'}
                                        label={'body'}
                                        defaultValue={editForm.body}
                                        onKeyUpCallback={(value: string) => {
                                            updateEditForm({ ...editForm, body: value })
                                            updateErrorState({ ...errorState, subject: '' })
                                        }}
                                        disabled={false}
                                        error={errorState.body}
                                    />
                                    
                                    <SlideOverFormInput
                                        name={'reply_to_phone'}
                                        label={'Reply Number'}
                                        defaultValue={editForm.reply_to_phone}
                                        onKeyUpCallback={(value: string) =>  {
                                            updateEditForm({ ...editForm, reply_to_phone: value })
                                            updateErrorState({ ...errorState, subject: '' })
                                        }}
                                        disabled={false}
                                        error={errorState.reply_to_phone}
                                    />
                                    
                                    <SlideOverFormInput
                                        name={'reply_to_email'}
                                        label={'Reply Email'}
                                        defaultValue={editForm.reply_to_email}
                                        onKeyUpCallback={(value: string) =>  {
                                            updateEditForm({ ...editForm, reply_to_email: value })
                                            updateErrorState({ ...errorState, subject: '' })
                                        }}
                                        disabled={false}
                                        error={errorState.reply_to_email}
                                    />

                                    {/* Action buttons */}
                                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                        <div className="space-x-3 flex justify-end">
                                            <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => closeModal()}
                                            >
                                            Cancel
                                            </button>
                                            <button
                                            type="submit"
                                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={handleFormSubmit}
                                            >
                                            Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <Transition.Root show={sendModalIsOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => closeSendModal()}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                    <div>
                        <div className="my-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                Send Email
                            </Dialog.Title>
                        </div>
                        <p className="text-sm text-center text-gray-500 mb-3">
                            The following guests will recieve the email.
                        </p>
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <div style={{
                                            maxHeight: '330px',
                                            overflowY: 'scroll',
                                        }}>
                                            <table className="min-w-full divide-y divide-gray-200 table-auto">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Email
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sendList && sendList.map((guest, guestIdx) => (
                                                            <tr key={guest.id} className={guestIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ guest.name }</td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ guest.email }</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={() => closeSendModal()}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className=" ml-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={async () => {
                                await sendEmail({
                                    speakerEventId: speakerEvent.id,
                                    emailId: emailInFocus,
                                });
                            }}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition.Root>
        </div>
    )
}