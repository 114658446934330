interface PropTypes {
    timeline: Array<any>;
}

export const Timeline = ({ timeline }: PropTypes) => {
    function classNames(...classes: any) {
      return classes.filter(Boolean).join(' ')
    }
    
    return (
        <div className="mt-6 flow-root">
            <ul role="list" className="-mb-8">
                {timeline.map((item, itemIdx) => (
                <li key={item.id}>
                    <div className="relative pb-8">
                    {itemIdx !== timeline.length - 1 ? (
                        <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                        />
                    ) : null}
                    <div className="relative flex space-x-3">
                        <div>
                        <span
                            className={classNames(
                            item.type.bgColorClass,
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                            )}
                        >
                            <item.type.icon className="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                            <p className="text-sm text-gray-500">
                            {item.content}{' '}
                            <a href="#" className="font-medium text-gray-900">
                                {item.target}
                            </a>
                            </p>
                        </div>
                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <time dateTime={item.datetime}>{item.date}</time>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                ))}
            </ul>
        </div>
    );
}