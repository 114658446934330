import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultBaseQuery } from "../api";
import { UpdateUserPassword } from "./types";

export const USER_API_REDUCER_KEY = 'userApi';
export const userApi = createApi({
    reducerPath: USER_API_REDUCER_KEY,
    baseQuery: defaultBaseQuery(),
    endpoints: (builder) => ({
        getUser: builder.query<any, any>({
            query: () => 'user',
        }),
        updateUserPassword: builder.mutation<UpdateUserPassword, any>({
            query: (patch) => ({
                url: '/user/password',
                method: 'PATCH',
                body: patch,
            }),
        }),
        getEmployees: builder.query<any, any>({
            query: () => 'employees',
        }),
    }),
});

export const { useGetUserQuery, useUpdateUserPasswordMutation, useGetEmployeesQuery } = userApi;