import { Dialog, Transition } from "@headlessui/react"
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { stringify } from "querystring"
import React, { useState, Fragment } from "react"
import { useAddSpeakerEventCostMutation, useRemoveSpeakerEventCostMutation, useUpdateSpeakerEventCostMutation } from "../../../api/speakerEvent/api"
import { SpeakerEvent, SpeakerEventCost, SpeakerEventCost422 } from "../../../api/speakerEvent/types"
import { Modal } from "../../../shared/components/Modal"
import { SlideOverFormCheckbox } from "../../../shared/components/slideOver/FormCheckbox"
import { SlideOverFormInput } from "../../../shared/components/slideOver/FormInput"
import { SlideOverHeader } from "../../../shared/components/slideOver/Header"
import { SlideOverSelect } from "../../../shared/components/slideOver/Select"
import { TabHeader } from "../../../shared/components/tabs/TabHeader"

const VAT = [
    {
        id: 1,
        name: "Standard Rate",
    },
    {
        id: 2,
        name: "Reduced Rate",
    },
    {
        id: 3,
        name: "Zero Rate",
    },
];

interface PropTypes {
    speakerEvent: SpeakerEvent;
};

export const SpeakerEventCosts = ({ speakerEvent }: PropTypes) => {
    const [ modalState, updateModalState ] = useState(false);
    const [ charge, updateCharge ] = useState<{
        id: null | number;
        name: string;
        unit_cost: number;
        quantity: null | number;
        vat: {
            id: number,
            name: string,
        };
        includes_vat: boolean;
    }>({
        id: null,
        name: '',
        unit_cost: 0,
        quantity: null,
        vat: {
            id: 1,
            name: 'Standard Rate',
        },
        includes_vat: false,
    });
    const [ deleteModalState, updateDeleteModalState ] = useState<{
        show: boolean,
        id: number,
    }>({
        show: false,
        id: 0,
    });
    const [ addSpeakerEventCost ] = useAddSpeakerEventCostMutation();
    const [ updateSpeakerEventCost ] = useUpdateSpeakerEventCostMutation();
    const [ removeSpeakerEventCost ] = useRemoveSpeakerEventCostMutation();
    const [ errorState , updateErrorState ] = useState<{
        name: string;
        unit_cost: string;
        quantity: string;
        vat_id: string;
        includes_vat: string;
    }>({
        name: '',
        unit_cost: '',
        quantity: '',
        vat_id: '',
        includes_vat: '',
    })

    const calcQuantity = (cost: SpeakerEventCost): number => cost.quantity === null ? speakerEvent.guests.length : cost.quantity

    const calcEstCost = (cost: SpeakerEventCost): number => {
        const est = cost.quantity === null ? cost.unit_cost * speakerEvent.guests.length : cost.unit_cost * cost.quantity

        return cost.includes_vat ? est : est * 0.8
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        let response: any = null;
        if (charge.id === null) {
            response = await addSpeakerEventCost({
                dinnerId: speakerEvent.id,
                name: charge.name,
                unit_cost: charge.unit_cost,
                quantity: charge.quantity,
                vat_id: charge.vat.id,
                includes_vat: charge.includes_vat,
            });
        } else {
            response = await updateSpeakerEventCost({
                id: charge.id,
                dinnerId: speakerEvent.id,
                name: charge.name,
                unit_cost: charge.unit_cost,
                quantity: charge.quantity,
                vat_id: charge.vat.id,
                includes_vat: charge.includes_vat,
            })
        }

        if (response.error) {
            var current = errorState;
            const error = response.error.data as SpeakerEventCost422;
            Object.keys(error.errors).forEach(key => {
                let e = '';
                switch (key) {
                    case 'name':
                        e = error.errors.name && error.errors.name.length > 0 ? error.errors.name[0] : '';

                        current.name = e;
                        break;
                    case 'quantity':
                        e = error.errors.quantity && error.errors.quantity.length > 0 ? error.errors.quantity[0] : '';

                        current.quantity = e;
                        break;
                    case 'unit_cost':
                        e = error.errors.unit_cost && error.errors.unit_cost.length > 0 ? error.errors.unit_cost[0] : '';

                        current.unit_cost = e;
                        break;
                    case 'vat_id':
                        e = error.errors.vat_id && error.errors.vat_id.length > 0 ? error.errors.vat_id[0] : '';

                        current.vat_id = e;
                        break;
                    case 'includes_vat':
                        e = error.errors.includes_vat && error.errors.includes_vat.length > 0 ? error.errors.includes_vat[0] : '';

                        current.includes_vat = e;
                        break;
                }
            })
            updateErrorState(current);

            return
        }

        closeModal();
    }

    const closeModal = () => {
        updateModalState(false);
        updateErrorState({
            name: '',
            unit_cost: '',
            quantity: '',
            vat_id: '',
            includes_vat: '',
        })
    }
    
    const handleRemoveCost = async () => {
        await removeSpeakerEventCost({
            dinnerId: speakerEvent.id,
            id: deleteModalState.id,
        })

        updateDeleteModalState({
            show: false,
            id: 0,
        });
    }

    return (
    <div className="mt-8 max-w-4xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-10">
            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white border-2 border-gray-100 shadow sm:rounded-lg">
                        <TabHeader
                            heading="Costs"
                            description="Costs and charges for speaker event."
                            createCallBack={() => {
                                updateCharge({
                                    id: null,
                                    name: '',
                                    unit_cost: 0,
                                    quantity: null,
                                    vat: {
                                        id: 1,
                                        name: 'Standard Rate',
                                    },
                                    includes_vat: false,
                                });

                                updateModalState(true);
                            }}
                        />
                        <div className="border-t border-gray-300 overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Unit Cost
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Est Cost
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        speakerEvent.costs.length > 0 && speakerEvent.costs.map(cost => (
                                            <tr key={1} className={true ? 'bg-white' : 'bg-gray-50'}>
                                                <td colSpan={1} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ cost.name }</td>
                                                <td colSpan={1} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{ calcQuantity(cost) }</td>
                                                <td colSpan={1} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{ cost.unit_cost }</td>
                                                <td colSpan={1} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{ calcEstCost(cost) }</td>
                                                <td colSpan={1} className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a 
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                        onClick={() => {
                                                            updateCharge({
                                                                id: cost.id,
                                                                name: cost.name,
                                                                unit_cost: cost.unit_cost,
                                                                quantity: cost.quantity,
                                                                vat: VAT.filter(vat => vat.id === cost.vat_id)[0],
                                                                includes_vat: cost.includes_vat,
                                                            })
                                                            updateModalState(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </a>
                                                    <a 
                                                        className="text-indigo-600 hover:text-indigo-900 ml-4"
                                                        onClick={() => cost.id !== null && updateDeleteModalState({
                                                            show: true,
                                                            id: cost.id,
                                                        })}
                                                    >
                                                        Delete
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        <Transition.Root show={modalState} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => closeModal()}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                        <div className="w-screen max-w-2xl">
                            <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <div className="flex-1">
                                    {/* Header */}
                                    <SlideOverHeader
                                        title={'Charges'}
                                        description={`Get started by filling in the information below to ${charge.id === null? 'add' : 'update' } your charge.`}
                                        closeCallback={() => closeModal()}
                                    />
                                    <SlideOverFormInput
                                        label="Name"
                                        name="name"
                                        onKeyUpCallback={(val: string) =>  {
                                            charge && updateCharge({ ...charge, name: val})
                                            updateErrorState({ ...errorState, name: ''})
                                        }}
                                        defaultValue={charge.name}
                                        error={errorState.name}
                                    />
                                    <SlideOverFormInput
                                        label="Unit Cost"
                                        name="unit_cost"
                                        onKeyUpCallback={(val: string) =>  {
                                            charge && updateCharge({ ...charge, unit_cost: Number(val)})
                                            updateErrorState({ ...errorState, unit_cost: ''})
                                        }}
                                        description="Cost is limited to 2 dp"
                                        defaultValue={String(charge.unit_cost)}
                                        error={errorState.unit_cost}
                                    />
                                    <SlideOverFormCheckbox
                                        label="Unit Cost VAT"
                                        name="includes_vat"
                                        onSelectCallback={(id: string) => {
                                            charge && updateCharge({ ...charge, includes_vat: id == "1"})
                                            updateErrorState({ ...errorState, includes_vat: ''})
                                        }}
                                        values={[
                                            {
                                                id: 1,
                                                value: 'Includes VAT',
                                                description: 'Quantity figure includes VAT',
                                            },
                                            {
                                                id: 2,
                                                value: 'Does not include VAT',
                                                description: 'Quantity does not include VAT',
                                            }
                                        ]}
                                        selected={[charge.includes_vat ? 1 : 2]}
                                        error={errorState.includes_vat}
                                    />
                                    <SlideOverFormCheckbox
                                        label="Quantity"
                                        name="Quantity"
                                        onSelectCallback={(id: string) => {
                                            charge && updateCharge({ ...charge, quantity: id == "2" ? null : 0})
                                            updateErrorState({ ...errorState, quantity: ''})
                                        }}
                                        values={[
                                            {
                                                id: 1,
                                                value: 'Fixed',
                                                description: 'Quantity is fixed, will not increase depending on guest count',
                                            },
                                            {
                                                id: 2,
                                                value: 'Per Guest',
                                                description: 'Quantity will increase depending on guest count',
                                            }
                                        ]}
                                        selected={[charge.quantity === null ? 2 : 1]}
                                        error={charge.quantity === 1 ? errorState.quantity : ''}
                                    />
                                    {
                                        charge.quantity != null && (
                                            <SlideOverFormInput
                                                label="Fixed Value"
                                                name="amount_value"
                                                onKeyUpCallback={(val: string) => {
                                                    charge && updateCharge({ ...charge, quantity: Number(val)})
                                                    updateErrorState({ ...errorState, quantity: ''})
                                                }}
                                                defaultValue={String(charge.quantity)}
                                                description="Fixed Quantity Value"
                                                error={errorState.quantity}
                                            />
                                        )
                                    }
                                    {
                                        VAT && (
                                            <SlideOverSelect
                                                name={'vat'}
                                                label={'Vat'}
                                                data={VAT.map(vat => {
                                                    return {
                                                        id: vat.id,
                                                        value: vat.name,
                                                    }
                                                })}
                                                callback={(id: number) => {
                                                    charge && updateCharge({ ...charge, vat: { id, name: charge.vat.name } } )
                                                    updateErrorState({ ...errorState, vat_id: ''})
                                                }}
                                                selected={charge.vat?.id ?? 1}
                                                error={errorState.vat_id}
                                            />
                                        )
                                    }
                                </div>

                                {/* Action buttons */}
                                <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                    <div className="space-x-3 flex justify-end">
                                        <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => closeModal()}
                                        >
                                        Cancel
                                        </button>
                                        <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={handleSubmit}
                                        >
                                        {
                                            charge.id === null ? 'Create' : 'Update'
                                        }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <Modal
            show={Boolean(deleteModalState.show)}
            close={() => updateDeleteModalState({ ...deleteModalState, show: false })}
            title={'Remove Cost From Dinner ?'}
            reject={{
                show: true,
                value: 'Close',
                callback: () => updateDeleteModalState({ ...deleteModalState, show: false })
            }}
            accept={{
                show: true,
                value: 'Remove',
                callback: handleRemoveCost
            }}
        />
        </div>
    )
}