import { Link } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import { SlideOverHeader } from "../../../shared/components/slideOver/Header";
import { SlideOverFormInput } from "../../../shared/components/slideOver/FormInput";
import { SlideOverDatepicker } from "../../../shared/components/slideOver/Datepicker";
import { EmployeeSearch } from "../../../shared/components/EmployeeSearch";
import { SlideOverPrivacy } from "../../../shared/components/slideOver/Privacy";
import { Fragment, useEffect, useState } from "react";
import { SideOverlay } from "../../../shared/components/SideOverlay";
import { Modal } from "../../../shared/components/Modal";
import { SpeakerEvent, SpeakerEventGuest, SpeakerEventGuest422, SpeakerEventGuestDeleteBody, SpeakerEventGuestType } from "../../../api/speakerEvent/types";
import { useFetchAllCompaniesQuery } from "../../../api/company/api";
import { SlideOverSelect } from "../../../shared/components/slideOver/Select";
import { useFetchAllAllergiesQuery } from "../../../api/allergies/api";
import { useFetchAllDietsQuery } from "../../../api/diets/api";
import { useAddSpeakerEventGuestMutation, useFetchGuestsQuery, useRemoveSpeakerEventGuestMutation, useUpdateSpeakerEventguestMutation } from "../../../api/speakerEvent/api";
import { TabHeader } from "../../../shared/components/tabs/TabHeader";

interface PropTypes {
    speakerEvent: SpeakerEvent;
};

export const SpeakerEventGuests = ({ speakerEvent }: PropTypes) => {
    const [ open, setOpen ] = useState(false);
    const [ deleteModel, setOpenDeleteModal ] = useState<{
        show: Boolean,
        data: SpeakerEventGuestDeleteBody,
    }>({
        show: false,
        data: {
            dinnerId: speakerEvent.id,
            guestId: 0,
        }
    });
    const [ openModal, setOpenModal ] = useState(false);
    const [ guest, updateGuest ] = useState<SpeakerEventGuest>();
    const [ guestError, updateGuestError ] = useState<{
        first_name: string;
        last_name: string;
        email: string;
        companyId: string;
        allergyId: string;
        dietId: string;
        type: string;
    }>({
        first_name: '',
        last_name: '',
        email: '',
        companyId: '',
        allergyId: '',
        dietId: '',
        type: '',        
    });
    const { data: companies, error, isLoading } = useFetchAllCompaniesQuery(null);
    const { data: allergies } = useFetchAllAllergiesQuery(null);
    const { data: diets } = useFetchAllDietsQuery(null);
    const [ updateGuestMutation] = useUpdateSpeakerEventguestMutation();
    const [ addGuestMutation ] = useAddSpeakerEventGuestMutation();
    const [ removeGuestMutation ] = useRemoveSpeakerEventGuestMutation();
    const [ searchIsShowing, showSearch ] = useState(false);
    const [ searchQuery, updateSearchQuery ] = useState<{
        dinnerId: number;
        query: string;
    }>({
        dinnerId: speakerEvent.id,
        query: '',
    });

    const [ searchQuerytmp, updateSearchQuerytmp ] = useState('');
    const { data: guests } = useFetchGuestsQuery(searchQuery, {
        skip: searchQuery.query.length > 0 === false,
    })

    const hideModal = () => {
        setOpen(false)
        showSearch(false)
        updateGuestError({
            first_name: '',
            last_name: '',
            email: '',
            companyId: '',
            allergyId: '',
            dietId: '',
            type: '',
        })
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (guest) {
            let response: any = undefined
            if (guest.id) {
                response = await updateGuestMutation({
                    dinnerId: speakerEvent.id,
                    id: guest?.id,
                    first_name: guest.first_name,
                    last_name: guest.last_name,
                    email: guest.email,
                    companyId: guest.company.id,
                    allergyId: guest.allergies.id,
                    dietId: guest.diets.id,
                })
            } else {
                response = await addGuestMutation({
                    dinnerId: speakerEvent.id,
                    first_name: guest.first_name,
                    last_name: guest.last_name,
                    email: guest.email,
                    companyId: guest.company.id,
                    allergyId: guest.allergies.id,
                    dietId: guest.diets.id,
                })
            }

            if (response.error) {
                var current = guestError;
                const error = response.error.data as SpeakerEventGuest422;
                Object.keys(error.errors).forEach(key => {
                    let e = '';
                    switch (key) {
                        case 'dietId':
                            e = error.errors.dietId && error.errors.dietId.length > 0 ? error.errors.dietId[0] : '';

                            current.dietId = e
                            break;
                        case 'allergyId':
                            e = error.errors.allergyId && error.errors.allergyId.length > 0 ? error.errors.allergyId[0] : '';

                            current.allergyId = e
                            break;
                        case 'first_name':
                            e = error.errors.first_name && error.errors.first_name.length > 0 ? error.errors.first_name[0] : '';

                            current.first_name = e
                            break;
                        case 'last_name':
                            e = error.errors.last_name && error.errors.last_name.length > 0 ? error.errors.last_name[0] : '';

                            current.last_name = e
                            break;
                        case 'email':
                            e = error.errors.email && error.errors.email.length > 0 ? error.errors.email[0] : '';

                            current.email = e
                            break;
                        case 'companyId':
                            e = error.errors.companyId && error.errors.companyId.length > 0 ? error.errors.companyId[0] : '';

                            current.companyId = e
                            break;
                    }
                })
                updateGuestError(current)

                return
            }
        }

        hideModal()
    }

    const handleRemoveGuest = async () => {
        const response = await removeGuestMutation(deleteModel.data);

        setOpenDeleteModal({ ...deleteModel, show: false });
    }

    return (
    <div className="mt-8 max-w-4xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-10">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white border-2 border-gray-100 shadow sm:rounded-lg">
                    <TabHeader
                        heading="Guests"
                        createCallBack={() => {
                            updateGuest({
                                id: null,
                                first_name: '',
                                last_name: '',
                                email: '',
                                emails: [],
                                company: {
                                    id: 0,
                                    name: '',
                                },
                                allergies: {
                                    id: 0,
                                },
                                diets: {
                                    id: 0,
                                },
                                type: {
                                    id: 0,
                                    name: '',
                                }
                            })
                            setOpen(true);
                        }}
                    />
                    <div className="border-t border-gray-300 overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Company
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Email
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                        <span className="sr-only">Emails</span>
                                        <span className="sr-only">Delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { speakerEvent.guests.map((guest, guestIdx) => (
                                <tr key={guest.id} className={guestIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ guest.first_name} { guest.last_name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{ guest.company.name }</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{ guest.email }</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <a 
                                            className="text-indigo-600 hover:text-indigo-900"
                                            onClick={() => {
                                                updateGuest(guest)
                                                setOpen(true);
                                            }}
                                        >
                                            Edit
                                        </a>
                                        { guest.emails.length > 0 && (
                                        <a 
                                            className="text-indigo-600 hover:text-indigo-900 ml-4"
                                            onClick={() => setOpenModal(true)}
                                        >
                                            Emails
                                        </a>
                                        )}
                                        <a 
                                            className="text-indigo-600 hover:text-indigo-900 ml-4"
                                            onClick={() => setOpenDeleteModal({
                                                show: true,
                                                data: {
                                                    dinnerId: speakerEvent.id,
                                                    guestId: Number(guest?.id),
                                                }
                                            })}
                                        >
                                            Remove
                                        </a>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => hideModal()}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                        <div className="w-screen max-w-2xl">
                            <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" onSubmit={e => e.preventDefault()}>
                                <div className="flex-1">
                                    {/* Header */}
                                    <SlideOverHeader
                                        title={guest?.id ? 'Edit a Guest' : 'Add a Guest'}
                                        description={`Get started by filling in the information below to ${guest?.id ? 'edit' : 'add'} your guest.`}
                                        closeCallback={() => hideModal()}
                                    />
                                    {
                                        guest?.id === null && searchIsShowing && (
                                            <>
                                                <SlideOverFormInput
                                                    name={'search'}
                                                    label={'Find Existing'}
                                                    defaultValue={searchQuery.query}
                                                    onKeyUpCallback={(value: string) => updateSearchQuerytmp(value)}
                                                />

                                                {/* Action buttons */}
                                                <div className="flex-shrink-0 px-4 border-b border-gray-200 py-5 sm:px-6">
                                                    <div className="space-x-3 flex justify-end">
                                                        <button
                                                        type="submit"
                                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        onClick={(e: any) => {
                                                            e.preventDefault();

                                                            updateSearchQuery({ ...searchQuery, query: searchQuerytmp })
                                                        }}
                                                        >
                                                        Search
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    guests && guests.length > 0 ? (
                                                        <div className="grid grid-flow-row auto-rows-max">
                                                            <div className="col-start-1 col-end-6 mx-auto">
                                                                <div className="flow-root mt-6">
                                                                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                                                                    {guests.map((guest) => (
                                                                        <li key={guest.id} className="py-4">
                                                                        <div className="flex items-center space-x-4">
                                                                            <div className="flex-1 min-w-0">
                                                                            <p className="text-sm font-medium text-gray-900 truncate">{guest.first_name} {guest.last_name}</p>
                                                                            <p className="text-sm text-gray-500 truncate">{guest.email}</p>
                                                                            </div>
                                                                            <div>
                                                                            <button
                                                                                className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-100"
                                                                                onClick={async (event) => {
                                                                                    const response = await updateGuestMutation({
                                                                                        dinnerId: speakerEvent.id,
                                                                                        id: guest.id,
                                                                                        first_name: guest.first_name,
                                                                                        last_name: guest.last_name,
                                                                                        email: guest.email,
                                                                                        companyId: guest.company.id,
                                                                                        allergyId: guest.allergies.id,
                                                                                        dietId: guest.diets.id,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                Add
                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                        </li>
                                                                    ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <h4>Nothing Found :/</h4>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        searchIsShowing === false && (
                                            <>
                                        <SlideOverFormInput
                                            name={'first_name'}
                                            label={'First Name'}
                                            defaultValue={guest?.first_name}
                                            onKeyUpCallback={(value: string) => {
                                                guest && updateGuest({ ...guest, first_name: value})
                                                updateGuestError({ ...guestError, first_name: '' })
                                            }}
                                            disabled={guest?.type.id === 1}
                                            error={guestError.first_name}
                                        />
                                        <SlideOverFormInput
                                            name={'last_name'}
                                            label={'Last Name'}
                                            defaultValue={guest?.last_name}
                                            onKeyUpCallback={(value: string) => {
                                                guest && updateGuest({ ...guest, last_name: value})
                                                updateGuestError({ ...guestError, last_name: '' })
                                            }}
                                            disabled={guest?.type.id === 1}
                                            error={guestError.last_name}
                                        />
                                        <SlideOverFormInput
                                            name={'email'}
                                            label={'Email'}
                                            defaultValue={guest?.email}
                                            onKeyUpCallback={(value: string) => {
                                                guest && updateGuest({ ...guest, email: value})
                                                updateGuestError({ ...guestError, email: '' })
                                            }}
                                            disabled={guest?.type.id === 1}
                                            error={guestError.email}
                                        />
                                        { companies && (
                                            <SlideOverSelect
                                                name={'company'}
                                                label={'Company'}
                                                data={companies.map(company => {
                                                    return {
                                                        id: company.id,
                                                        value: company.name,
                                                    }
                                                })}
                                                callback={(id: number) => {
                                                    guest && updateGuest({ ...guest, company: { id: id, name: guest.company.name }})
                                                    updateGuestError({ ...guestError, companyId: '' })
                                                }}
                                                selected={guest?.company.id ?? 0}
                                                disabled={guest?.type.id === 1}
                                                error={guestError.companyId}
                                            />
                                        )}
                                        { allergies && (
                                            <SlideOverSelect
                                                name={'allergy'}
                                                label={'Allergy'}
                                                data={allergies.map(allergy => {
                                                    return {
                                                        id: allergy.id,
                                                        value: allergy.name,
                                                    }
                                                })}
                                                callback={(id: number) => {
                                                    guest && updateGuest({ ...guest, allergies: { id } })
                                                    updateGuestError({ ...guestError, allergyId: '' })
                                                }}
                                                selected={guest?.allergies?.id ?? 0}
                                                error={guestError.allergyId}
                                            />
                                        )}
                                        { diets && (
                                            <SlideOverSelect
                                                name={'diet'}
                                                label={'Diet'}
                                                data={diets.map(diet => {
                                                    return {
                                                        id: diet.id,
                                                        value: diet.name,
                                                    }
                                                })}
                                                callback={(id: number) => {
                                                    guest && updateGuest({ ...guest, diets: { id }})
                                                    updateGuestError({ ...guestError, dietId: '' })
                                                }}
                                                selected={guest?.diets?.id ?? 0}
                                                error={guestError.dietId}
                                            />
                                        )}
                                        </>
                                    )}
                                </div>

                                {/* Action buttons */}
                                <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                    <div className="space-x-3 flex justify-end">
                                        { guest?.id === null && (
                                            <button
                                            type="submit"
                                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={(event: any) => {
                                                event.preventDefault()
                                                showSearch(!searchIsShowing)
                                            }}
                                            >
                                                {
                                                    searchIsShowing ? 'Hide Search' : 'Show Search'
                                                }
                                            </button>
                                        )}
                                        <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => hideModal()}
                                        >
                                        Cancel
                                        </button>
                                        {
                                            searchIsShowing === false && (
                                                <button
                                                type="submit"
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={handleFormSubmit}
                                                >
                                                    Update
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <Modal
            show={openModal}
            close={setOpenModal}
            title={'Emails Sent'}
            reject={{
                show: true,
                value: 'Close',
                callback: () => setOpenModal(false)
            }}
            accept={{
                show: false,
                value: 'Delete',
                callback: () => setOpenModal(false)
            }}
        />
        <Modal
            show={Boolean(deleteModel.show)}
            close={() => setOpenDeleteModal({ ...deleteModel, show: false })}
            title={'Remove Guest From Dinner ?'}
            reject={{
                show: true,
                value: 'Close',
                callback: () => setOpenDeleteModal({ ...deleteModel, show: false })
            }}
            accept={{
                show: true,
                value: 'Remove',
                callback: handleRemoveGuest
            }}
        />
    </div>
    );
}